import "./TermofuseContainer.css";

const TermofuseContainer = ({ displaycontainer,setDisplaycontainer}) => {
  return (
    <div className="privacycontainerterm" style={{ display: displaycontainer }}>
      <div className="headingtermandprivast">
        <span>Terms of Use</span>
        <span className="close-icon" onClick={() => setDisplaycontainer('none')}>
          <svg
            className="h-10 w-10 text-red-600"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="12" r="9" />
            <path d="M10 10l4 4m0 -4l-4 4" />
          </svg>
        </span>
      </div>
      <div className="Contentoftermandcondition">
        <p>
          These terms of use are entered into by and between You and Tipsmagnet, Inc. (“Company,” “we,” or “us”). The
          following terms and conditions, together with any documents they expressly incorporate by reference
          (collectively, “Terms of Use”), govern your access to and use of www.tipsmagnet.co, and any user interface,
          content, functionality, and services offered on or through these domains or by us (collectively, the
          “Website”).
        </p>
        <h2>Acceptance of the Terms of Use</h2>
        <p>
          Please read the Terms of Use carefully before you start to use the Website. By using the Website or
          accepting or agreeing to the Terms of Use when you click any of the pay buttons in the user interface, you
          accept and agree to be bound and abide by these Terms of Use and our Privacy Policy (the “Privacy Policy”),
          incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy,
          you must not access or use the Website.
        </p>
        <h2>Changes to the Terms of Use</h2>
        <p>
          We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
          effective immediately when we post them and apply to all access to and use of the Website thereafter.
          However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will
          not apply to any disputes for which the parties have actual notice on or before the date the change is
          posted on the Website.
        </p>
        <h2>What We Do</h2>
        <p>
          We provide a user interface to allow our business clients to use unique QR codes to provide customers a way
          to leave tips for their service professionals and provide feedback to those businesses regarding those
          services. This user interface allows a customer to leave a tip by making a digital payment instead of
          leaving cash.
        </p>
        <h2>Processing through Stripe</h2>
        <p>
          The payment processing services on our Website are provided by a third party. For businesses to allow us to
          enable payment processing services, you must provide us accurate and complete information about you and
          your business and authorize us to share it and transaction information related to your use of the payment
          processing services provided by to us.
        </p>
        <h2>Fees and Disputes</h2>
        <p>
          When you submit a tip through our user-interface, the convenience fee that we charge on the tip will be
          disclosed. If you have a dispute about a tip entered with us, you may contact us at <a href="mailto:contact@tipsmagnet.co">contact@tipsmagnet.co</a>.
        </p>
        <h2>Intellectual Property Rights</h2>
        <p>
          The Website and its entire contents, features, and functionality (including but not limited to all
          information, software, text, displays, images, video, and audio, and the design, selection, and arrangement
          thereof) are owned by the Company, its licensors, or other providers of such material and are protected by
          United States and international copyright, trademark, patent, trade secret, and other intellectual property
          or proprietary rights laws.
        </p>
        <p>
          These Terms of Use permit you to use the Website for your personal, non-commercial use only or for
          legitimate business purposes as agreed by the Company. You must not reproduce, distribute, modify, create
          derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the
          material on our Website, except as follows:
        </p>
        <ul>
          <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
          <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
          <li>
            You may print or download one copy of a reasonable number of pages of the Website for your own personal,
            non-commercial use and not for further reproduction, publication, or distribution.
          </li>
          <li>
            If we provide desktop, mobile, or other applications for download, you may download a single copy to your
            computer or mobile device solely for your own personal, non-commercial use, provided you agree to be
            bound by our end user license agreement for such applications.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TermofuseContainer;
