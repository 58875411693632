import React, { useState } from 'react';
import TermofuseContainer from "../termofusepopup/TermofuseContainer";
import PrivacypolicyContainer from '../privacypolictcontainer/PrivacypolicyContainer';
import "./PrivacyContainer.css";

const PrivacyContainer = () => {
  const [displaycontainerTerm, setDisplaycontainerTerm] = useState("");
  const [displaycontainerPrv, setDisplaycontainerPrv] = useState("");

  const handleTermsClick = () => {
    setDisplaycontainerTerm("block");
  };
  const handlePrvClick = () => {
    setDisplaycontainerPrv("block");
  };

  return (
    <>
      <div className="Mainboxprivacy">
        <div>By proceeding, you accept and agree to Tipsmagnet's</div>
        <div className="termandcondi">
          <span className="Termprivacycolor" onClick={handleTermsClick}>
            Terms of Use
          </span> and <span className="Termprivacycolor" onClick={handlePrvClick}>Privacy Policy.</span>
        </div>
      </div>
      <TermofuseContainer displaycontainer={displaycontainerTerm} setDisplaycontainer={setDisplaycontainerTerm} />
      <PrivacypolicyContainer displaycontainer={displaycontainerPrv} setDisplaycontainer={setDisplaycontainerPrv}/>
    </>
  );
};

export default PrivacyContainer;
