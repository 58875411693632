import "./PrivacypolicyContainer.css";

const PrivacypolicyContainer = ({ displaycontainer, setDisplaycontainer }) => {
  return (
    <div className="privacycontainerPrv" style={{ display: displaycontainer }}>
      <div className="headingtermandprivast">
        <span>Privacy Policy</span>
        <span className="close-icon" onClick={() => setDisplaycontainer('none')}>
          <svg
            className="h-10 w-10 text-red-600"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="12" r="9" />
            <path d="M10 10l4 4m0 -4l-4 4" />
          </svg>
        </span>
      </div>
      <div className="Contentoftermandcondition">
        <h2>Privacy Policy for Tipsmagnet, Inc.</h2>
        <p>
          At Tipsmagnet, Inc. (“Company,” “we,” or “us”), your privacy is a priority, and we are committed to safeguarding your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you visit our website, www.tipsmagnet.co, or interact with any features, content, or services provided by us (collectively, the “Website”).
        </p>
        
        <h2>Information We Collect</h2>
        <p>We gather various types of information from users, including:</p>
        <ul>
          <li><strong>Personal Information:</strong> This includes data that can be used to identify you, such as your name, email address, and phone number.</li>
          <li><strong>Non-Personal Information:</strong> This includes details that do not directly identify you but relate to your browsing behavior, device information, and preferences.</li>
          <li><strong>Automatically Collected Information:</strong> As you navigate the Website, we may collect data such as IP addresses, browser types, and pages visited using cookies, web beacons, and similar technologies.</li>
        </ul>

        <h2>How We Use Your Information</h2>
        <p>The information we collect helps us to:</p>
        <ul>
          <li>Provide and improve our services.</li>
          <li>Customize your experience on our Website.</li>
          <li>Communicate with you about updates, promotions, and services.</li>
          <li>Protect against fraudulent or unauthorized transactions.</li>
          <li>Analyze and improve the performance and content of our Website.</li>
        </ul>

        <h2>Sharing Your Information</h2>
        <p>We do not sell or rent your personal information to third parties. However, we may share your data with:</p>
        <ul>
          <li><strong>Service Providers:</strong> We may share information with trusted third-party service providers who help us operate our business and the Website.</li>
          <li><strong>Legal Compliance:</strong> We may disclose information to comply with legal obligations or to protect our rights and the rights of others.</li>
          <li><strong>Business Transfers:</strong> In the event of a merger, sale, or acquisition, your information may be transferred as part of the transaction.</li>
        </ul>

        <h2>Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar tracking technologies to enhance your browsing experience, analyze site traffic, and understand user preferences. You can manage your cookie preferences through your browser settings, but please note that disabling cookies may affect your ability to use certain features of the Website.
        </p>

        <h2>Your Choices and Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access, update, or delete your personal information.</li>
          <li>Opt-out of receiving marketing communications from us.</li>
          <li>Control how we use your data for targeted advertising.</li>
        </ul>
        <p>
          To exercise these rights, please contact us at <a href="mailto:contact@tipsmagnet.co">contact@tipsmagnet.co</a>.
        </p>

        <h2>Data Security</h2>
        <p>
          We employ security measures designed to protect your personal information from unauthorized access and disclosure. However, no method of transmission over the internet or electronic storage is completely secure. While we strive to protect your data, we cannot guarantee absolute security.
        </p>

        <h2>Children’s Privacy</h2>
        <p>
          Our Website is not intended for children under 16 years of age. We do not knowingly collect personal information from children under 16. If we discover that we have inadvertently collected such information, we will take steps to delete it. If you believe we may have collected information from a child under 16, please contact us immediately at <a href="mailto:contact@tipsmagnet.co">contact@tipsmagnet.co</a>.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page, and we encourage you to review this policy regularly. Your continued use of the Website after any updates constitutes your acceptance of the revised policy.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our data practices, please reach out to us at <a href="mailto:contact@tipsmagnet.co">contact@tipsmagnet.co</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacypolicyContainer;
